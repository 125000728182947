import { Route, Routes } from "react-router-dom";
import "typeface-nunito-sans";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import GlobalStyles from "@mui/material/GlobalStyles";
import { globalStyle } from "./GlobalStyle";
import Header from "./components/Header";
import Login from "./routes/Login";
import StatusPending from "./routes/StatusPending";
import ForgotPassword from "./routes/ForgotPassword";
import AccountSettings from "./routes/AccountSettings";
import { ToastContainer } from "react-toastify";
import AuthProvider from "./components/AuthProvider";
import "react-toastify/dist/ReactToastify.min.css";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./routes/Dashboard";
import Services from "./routes/Services";
import Plans from "./routes/Plans/";
import AddressAndAccountContextProvider from "./components/AddressAndAccountProvider/AddressAndAccountProvider";
import SubscribeToISP from "./routes/SubscribeToISP";
import Profile from "./routes/Profile/Profile";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import {
  IconDefinition,
  IconPrefix,
  IconPack,
} from "@fortawesome/free-solid-svg-icons";

type IconDefinitionOrPack = IconDefinition | IconPack;

interface ImportedIcons {
  [key: string]: IconPrefix | IconDefinitionOrPack;
}

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => (Icons as ImportedIcons)[icon]);

library.add(...(iconList as IconDefinitionOrPack[]));

const materialUITheme = createTheme({
  typography: {
    fontFamily: theme.fontFamily,
  },
});

export const routes = {
  login: {
    name: "login",
    path: "/",
    element: <Login />,
    hasHeader: true,
  },
  forgotPassword: {
    name: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    hasHeader: true,
  },
  updateAccount: {
    name: "update-account",
    path: "/update-account",
    element: (
      <ProtectedRoute>
        <AccountSettings />
      </ProtectedRoute>
    ),
    hasHeader: true,
  },
  subscribeToISP: {
    name: "subscribeToISP",
    path: "/subscribe/:serviceId/:planId",
    element: (
      <ProtectedRoute>
        <SubscribeToISP />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  createAccount: {
    name: "create-account",
    path: "/create-account",
    element: <AccountSettings />,
    hasHeader: true,
  },
  statusPending: {
    name: "status-pending",
    path: "/status-pending",
    element: (
      <ProtectedRoute>
        <StatusPending />
      </ProtectedRoute>
    ),
    hasHeader: true,
  },
  dashboard: {
    name: "dashboard",
    path: "/dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  services: {
    name: "services",
    path: "/services",
    element: (
      <ProtectedRoute>
        <Services />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
  plans: {
    name: "plans",
    path: "/services/:id/plans",
    element: (
      <ProtectedRoute>
        <Plans />
      </ProtectedRoute>
    ),
    hasHeader: false,
  },

  profile: {
    name: "profile",
    path: "/profile",
    element: (
      <ProtectedRoute>
        <Dashboard>
          <Profile />
        </Dashboard>
      </ProtectedRoute>
    ),
    hasHeader: false,
  },
};

const App = () => (
  <ThemeProvider theme={materialUITheme}>
    <GlobalStyles styles={globalStyle} />
    <ToastContainer />
    <AuthProvider>
      <AddressAndAccountContextProvider>
        <Routes>
          {Object.values(routes).map(({ name, path, element, hasHeader }) => (
            <Route
              key={name}
              path={path}
              element={
                hasHeader ? (
                  <>
                    <Header />
                    {element}
                  </>
                ) : (
                  element
                )
              }
            />
          ))}
        </Routes>
      </AddressAndAccountContextProvider>
    </AuthProvider>
  </ThemeProvider>
);

export default App;
