import React, { useContext, useState, useMemo } from 'react';
import { Stack } from '@mui/material';
import getREMFromPX from '../../utils/getREMFromPX';
import { theme } from '../../theme';
import Label from '../Label';
import { useForm } from 'react-hook-form';
import Select from '../Select/Select';
import SelectOption from '../SelectOption';
import Input from '../Input';
import { endpoints } from "../../api/constants/endpoints";
import { useUpdateAccount } from "../../hooks/useUpdateAccount";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import sendErrorToast from "../../utils/sendErrorToast";
import sendSuccessToast from "../../utils/sendSuccessToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import fetcher from "../../utils/fetchWrapper";
import Button from "../../components/Button";

export interface AchInfo {
  stepName?: string;
  routingNum?: string;
  accountNum?: string;
  accountType?: string;
  accountHolderType?: string;
  amount1?: string;
  amount2?: string;
};

interface WarrantyInfo {
  paymentMethod: string;
  maintenanceSel: boolean;
  insuranceSel: boolean;
};

interface AchProps {
  children?: React.ReactElement;
  onSubmit: Function, 
  onSuccess?: Function,
  setIsValid: (isValid: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  previousValues: AchInfo;
  disableForm?: boolean;
  accountId?: string;
  warranty?: WarrantyInfo;
};

const Ach = ({ previousValues, children, onSubmit, setIsValid, onSuccess, disableForm, warranty, ...props }: AchProps) => {
  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
 
  const userId = userInfo?.data?.user?.id ?? "";
  const accountId = useMemo(() => props.accountId ?? userInfo?.data?.user?.Accounts?.[0]?.id ?? "", [userInfo, props.accountId]);

  const [verificationRequired, setVerificationRequired] = useState(false);

  const { mutateAsync: updateUser } = useUpdateUser(
    userId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error activating the user."
        ),
    }
  );

  const { mutateAsync: updateAccount } = useUpdateAccount(
    accountId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error activating the account."
        ),
    }
  );

  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<AchInfo>({
    mode: "onChange",
    defaultValues: {
      ...previousValues,
    },
  });

  const routingNum = watch("routingNum");
  const accountNum = watch("accountNum");
  const accountType = watch("accountType");
  const accountHolderType = watch("accountHolderType");
  const amount1 = watch("amount1");
  const amount2 = watch("amount2");

  const onPaymentSuccess = async () => {
    // User and Account need to be set active because payment was accepted
    await updateUser({
      status: "ACTIVE",
    });
    await updateAccount({
      status: "ACTIVE",
    });

    sendSuccessToast("Payment was processed successfully");
    onSuccess?.();
    onSubmit({});
  }

  const submitInfo = async () => {
    props.setIsLoading?.(true);
    if (!accountId) {
      sendErrorToast(
        "No account ID found"
      )
      return
    }

    
    let arr = [];
    if(warranty?.maintenanceSel)
      arr.push("utility");
    if(warranty?.insuranceSel) 
      arr.push("vbg_insurance");

    const body = {
      ach_routing_num: routingNum,
      ach_account_num: accountNum,
      ach_account_type: accountType,
      ach_account_holder_type: accountHolderType,
      items: arr,
      is_default_payment_method: true,
    };

    const res = await fetcher(`${endpoints.account}/${accountId}/achbilling`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    const paymentResponse = await res.json();

    if (paymentResponse?.data?.account?.subscription_created) {
      onPaymentSuccess();
    } else if (paymentResponse?.data?.account?.ach_verification_required) {
      // setVerificationRequired(true);
      onSubmit({});
    } else if (!paymentResponse?.data?.account) {
      sendErrorToast(paymentResponse?.message);
    }

    props.setIsLoading?.(false);
  };

  const submitVerification = async () => {
    props.setIsLoading?.(true);
    const body = {
      amounts: [amount1, amount2],
    };

    const res = await fetcher(`${endpoints.account}/${accountId}/achverify`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    const response = await res.json();

    if (response?.data?.account?.subscription_created) {
      onPaymentSuccess();
    } else {
      sendErrorToast(response?.message);
    }

    props.setIsLoading?.(false);
  }
  
  const onSubmitHandler = async () => {
    verificationRequired ? submitVerification() : submitInfo();
  };

  const disable = false;

  // This will notify parent component if this form is valid or not.
  setIsValid(isValid && !disable);
  
  return (
    <Stack>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack
          spacing={getREMFromPX(theme.spacing * 3)}
          mt={getREMFromPX(theme.spacing * 8)}
          style={{ pointerEvents: verificationRequired ? "none" : "auto" }}
        >
          <Label style={{ ...verificationRequired && { color: 'grey' } }} htmlFor="routingNum">Routing Number</Label>
          <Input
            data-testid="routingNum"
            placeholder=""
            {...register("routingNum", { required: true, pattern: { value: /^\d{9}$/, message: "Invalid routing number" }})}
            error={!!errors.routingNum && !!routingNum?.length}
            disabled={verificationRequired}
          />
          <Label style={{ ...verificationRequired && { color: 'grey' } }} htmlFor="accountNum">Account Number</Label>
          <Input
            data-testid="accountNum"
            placeholder=""
            {...register("accountNum", { required: true, pattern: { value: /^\d{8,17}$/, message: "invalid account number" }})}
            error={!!errors.accountNum && !!accountNum?.length}
            disabled={verificationRequired}
          />
          <Label style={{ ...verificationRequired && { color: 'grey' } }} htmlFor="accountType">Account Type</Label>
          <Select
            {...register("accountType", { required: true })}
            id="accountType"
            defaultValue="CHECKING"
            disabled={verificationRequired}
          >
            {[{ key: "Checking", value: "CHECKING" }, { key: "Savings", value: "SAVINGS" }]
              .map((val, idx) => (
                <SelectOption data-testid={val.key} key={val.key} value={val.value}>
                  {val.key}
                </SelectOption>
              ))}
          </Select>
          <Label style={{ ...verificationRequired && { color: 'grey' } }} htmlFor="accountHolderType">Account Holder Type</Label>
          <Select
            {...register("accountHolderType", { required: true })}
            id="accountHolderType"
            defaultValue="PERSONAL"
            disabled={verificationRequired}
          >
            {[{ key: "Personal", value: "PERSONAL" }, { key: "Business", value: "BUSINESS" }]
              .map((val, idx) => (
                <SelectOption data-testid={val.key} key={val.key} value={val.value}>
                  {val.key}
                </SelectOption>
              ))}
          </Select>
        </Stack>
        {verificationRequired && (
          <Stack
            spacing={getREMFromPX(theme.spacing * 3)}
            mt={getREMFromPX(theme.spacing * 8)}
          >
            <Button
              data-testid="editButton"
              text="Edit Payment Info"
              size="medium"
              onClick={() => setVerificationRequired(false)}
            />
            <div>
              Two small deposits were sent to your account to help verify the information you sent. This may take up to 10 minutes. Do not close this window during this process. Log in to your bank account and enter the small deposit amounts in the fields below.
            </div>
            <Label htmlFor="amount1">First Microdeposit Amount</Label>
            <Input
              data-testid="amount1"
              placeholder=""
              {...register("amount1", { required: verificationRequired, pattern: { value: /^\d{1,2}$/, message: "Invalid amount" }})}
              error={!!errors.amount1 && !!amount1?.length}
            />
            <Label htmlFor="amount2">Second Microdeposit Amount</Label>
            <Input
              data-testid="amount2"
              placeholder=""
              {...register("amount2", { required: verificationRequired, pattern: { value: /^\d{1,2}$/, message: "invalid amount" }})}
              error={!!errors.amount2 && !!amount2?.length}
            />
          </Stack>
        )}
        { children }
      </form>
    </Stack>
  );
}

export default Ach;