export const theme: {
    [key: string]: any;
} = {
  spacing: 4,
  /* Note: fontFamily should NOT be edited unless the new font is installed first */
  fontFamily: "Nunito Sans, Roboto, sans-serif",
  fonts: {
    weights: {
      normal: 400,
      bold: 500,
      mediumBold: 600,
      bolder: 700,
    },
  },
  backgrounds: {
    main: "#F6F6F6",
    widget: "#FFFFFF",
    table: "#FAFAFA",
    primarySelection: "#F8FCF9",
    secondarySelection: "#F7FAFD",
    tertiarySelection: "#e6e6e6",
  },
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.04), inset 0px 0px 1px #BABABF",
  default: {
    colors: {
      lightWarningStatus: "#F84D04",
      strongWarningStatus: "#E53934",
      okStatus: "#4CAF81",
      white: "#FFFFFF",
      red: "red",
      externalLink: "#0299FD",
      finalStepperFormIconColor: "#04A7F8",
      lightBorder: "#E4E7E5",
      primary: {
        900: "#1E3E26",
        700: "#2E613B",
        500: "#3F8450",
        300: "#97CEA5",
        50: "#EEF7F0",
      },
      secondary: {
        900: "#0F2034",
        700: "#1A395B",
        500: "#255183",
        300: "#88B0DD",
        50: "#EBF2F9",
      },
      textPrimary: {
        primary: "#303632",
        secondary: "#616B64",
        placeholder: "#79867D",
        disabled: "#AFB6B1",
      },
      textSecondary: {
        primary: "#FFFFFF",
        secondary: "#F2F3F2",
        placeholder: "#AFB6B1",
        disabled: "#79867D",
      },
    },
  },
  wraps: "wrap !important"
};
