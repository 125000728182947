import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { Review, PlanReviews } from "../api/interfaces/Review";
import fetcher from "../utils/fetchWrapper";

interface PlanReviewsProps {
  planId: string;
}
export {}

const getPlanReviews = async ({ planId }: PlanReviewsProps) => {
  const response = await fetcher(
    `${endpoints.getPlanReviews}/${planId}/Review?loading=User,Subscriptionhistory.Serviceplan&filter=status eq ACTIVE`
  );

  if (!response.ok) {
    throw new Error();
  }

  return await response.json();
};

export const useGetPlanReviews = (
  planId: string,
  options?: UseQueryOptions<Review, Error, PlanReviews, Array<string>>
): UseQueryResult<PlanReviews, Error> =>
  useQuery(["planReviews", planId],
  () => getPlanReviews({ planId }),
  options
);