import {
  Stack,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import { useCreateAccount } from "../../hooks/useCreateAccount";
import { useLinkAccountUser } from "../../hooks/useLinkAccountUser";
import { useLinkAccountDevice } from "../../hooks/useLinkAccountDevice";
import { useCreateUser } from "../../hooks/useCreateUser";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { useGetConfig } from "../../hooks/useGetConfig";
import useGetUserId from "../../hooks/useGetUserId";
import sendErrorToast from "../../utils/sendErrorToast";
import Box from "../../components/Box";
import EntryPointLink from "../../components/EntryPointLink";
import FinalStepScreenCustom from "../../components/FinalStepScreen/FinalStepScreenCustom";
import Stepper from "../../components/Stepper";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import DeviceStep from "./components/DeviceStep"
import PaymentStep from "./components/PaymentStep";
import WarrantyStep from "./components/WarrantyStep";
import { PaymentType } from "../../components/Payment";
import Button from "../../components/Button";
import { SingleUser } from "../../api/interfaces/User";
import { stepperContainerStyle } from "./Styles";

export type Step1Type = {
  stepName?: string;
  userName: string;
  password: string;
  confirmPassword: string;
};

export type Step2Type = {
  stepName?: string;
  name: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
};

export type Step3Type = {
  stepName?: string;
  question1Option: string;
  question2Option: string;
  question1Answer: string;
  question2Answer: string;
};

export type DeviceStepType = {
  stepName: string;
  deviceSerial: string;
};

export type WarrantyStepType = {
  stepName?: string;
  maintenanceSel: boolean;
  insuranceSel: boolean;
  paymentMethod: string;
  processingFeeSel: boolean;
  withoutInsurance: boolean;
  maintainceCharges: number,
  insuranceCharges: number,
  ccProcessingCharges: number,
  subtotal: number,
};

type FormDataType = {
  step1: Step1Type,
  step2: Step2Type,
  step3: Step3Type,
  deviceStep: DeviceStepType,
  paymentStep: PaymentType,
  warrantyStep: WarrantyStepType;
};

const AccountSettings = () => {
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const { provider, userInfo, setUserInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const [retryAccount, setRetryAccount] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();
  const userId = useGetUserId();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.updateAccount.path)
    }
  }, [isAuthenticated, navigate]);

  const fail = (message: string) => {
    setActiveStep(step => step - 1);
    sendErrorToast(message);
  }

  const { data: config } = useGetConfig();
  const showBilling = config?.data?.config?.enabled_features?.BILLING?.enabled;

  const { mutateAsync: updateUser, data: updatedUser } = useUpdateUser(
    userId,
    {
      onError: () => {
        fail("There was an error updating the user.");
      }
    }
  );

  const {
    mutateAsync: createAccount,
  } = useCreateAccount({
    onError: () => {
      setRetryAccount(true);
      fail("There was an error creating the account, please try again");
    },
  });

  const {
    mutateAsync: linkUser,
  } = useLinkAccountUser({
    onError: () =>
      fail("There was an error linking the user and account."),
  });

  const {
    mutateAsync: linkDevice,
  } = useLinkAccountDevice({
    onError: () =>
      fail("There was an error linking the device and account."),
  });

  const onAccountSuccess = useCallback(async (uid: string, resp: any) => {
    const account = resp.data?.account;
    if (!account?.id || resp.message) {
      fail(resp.message ?? "Could not create account");
      setRetryAccount(true);
      return;
    }

    // Link the user to the account
    linkUser({ accountId: account.id, userId: uid });

    // Link the device to the account
    linkDevice({ accountId: account.id, deviceId });

    // Set account ID for payment screen
    setAccountId(account.id);
    setActiveStep(step => step + 1);
  }, [setAccountId, setActiveStep, deviceId, linkDevice, linkUser])

  const contact = userInfo?.data?.user?.Contact

  const [formValues, setFormValues] = useState({
    step1: {
      stepName: "step1",
      userName: userInfo?.data?.user?.username ?? "",
      password: "",
      confirmPassword: "",
    },
    step2: {
      stepName: "step2",
      name: contact?.first_name ?? "",
      lastName: contact?.last_name ?? "",
      email: contact?.email ?? "",
      phoneNumber: contact?.phone ?? "",
      address: contact?.Location?.address1 ?? "",
      city: contact?.Location?.city ?? "",
      state: contact?.Location?.state ?? "",
      zipCode: contact?.Location?.zip ?? "",
    },
    step3: {
      stepName: "step3",
      question1Option: "",
      question2Option: "",
      question1Answer: "",
      question2Answer: "",
    },
    deviceStep: {
      stepName: "deviceStep",
      deviceSerial: "",
    },
    paymentStep: {
      stepName: "paymentStep",
      cardHolderName: "",
      cardNumber: "",
      expirationDate: "",
      cvc: "",
      zipCode: "",
      routingNum: "",
      accountNum: "",
      accountType: "CHECKING",
      accountHolderType: "PERSONAL",
    },
    warrantyStep: {
      stepName: "warrantyStep",
      maintenanceSel: false,
      insuranceSel: false,
      paymentMethod: "",
      processingFeeSel: false,
      withoutInsurance: false,
      maintainceCharges: 0,
      insuranceCharges: 0,
      ccProcessingCharges: 0,
      subtotal: 0,
    },
  });

  const getAccountNumber = useCallback(() => {
    return `${formValues.step2.lastName}_${formValues.step2.name}_${formValues.step2.address.replaceAll(" ", "_")}`
  }, [formValues]);

  const {
    mutateAsync: createUser,
    data: newUserData,
  } = useCreateUser({
    onError: () => {
      fail("There was an error creating the user, please try again");
    },
    onSuccess: (usr) => {
      const uid = usr?.data?.user?.id;
      if (uid && deviceId) {
        createAccount({
          account_number: getAccountNumber(),
          name: `${formValues.step2.lastName}, ${formValues.step2.name}`,
          status: showBilling ? "PENDING" : "ACTIVE",
          type: "RESIDENTIAL",
        }).then(async resp => {
          onAccountSuccess(uid, resp);
        });
      }
    },
  });
  
  const onSubmit = useCallback(async (formData:FormDataType) => {
    setSubmitting(true);
    const body = {
      force_username: false,
      username: formData.step1.userName,
      force_password: false,
      password: formData.step1.password,
      roles: ["SUBSCRIBER"],
      status: showBilling ? "PENDING" : "ACTIVE",
      can_review: true,
      default_url_subscriber_portal: "/dashboard#/account/status",
      ...(!userInfo?.data?.user?.answered_security_questions || !isAuthenticated) && {
        security_answer1: formData.step3.question1Answer,
        security_answer2: formData.step3.question2Answer,
        security_question1: formData.step3.question1Option,
        security_question2: formData.step3.question2Option,
      },
      default_url_management_portal: "",
      messaging: {
        ACCOUNT: ["EMAIL", "PORTAL"],
        FIRMWARE: ["EMAIL", "PORTAL"],
        OPERATOR_MESSAGE: ["EMAIL", "PORTAL"],
        SUBSCRIPTION: ["EMAIL", "PORTAL"],
        USER: ["EMAIL", "PORTAL"],
      },
      account_id: "",
      Contact: {
        id: "",
        provider_id: "",
        first_name: formData.step2.name,
        last_name: formData.step2.lastName,
        email: formData.step1.userName,
        phone: formData.step2.phoneNumber,
        status: "ACTIVE",
        type: "BILLING",
        Location: {
          id: "",
          address1: formData.step2.address,
          address2: "",
          city: formData.step2.city,
          state: formData.step2.state,
          zip: formData.step2.zipCode,
          country: "US",
          lat: "",
          lon: "",
          elevation: "",
          uri: "",
        },
      },
      loading: "Contact.Location",
    };

    if (isAuthenticated) {
      await updateUser(body).then(res => {
        setActiveStep(step => step + 1);
        setUserInfo((info: SingleUser) => {
          return {
            data: {
              user: {
                ...info?.data?.user,
                ...res?.data?.user
              },
            },
          };
        });
      }).catch(e => console.log('UPDATE USER ERROR', e));
    } else if (!newUserData) {
      await createUser(body).then(res => {
        setUserInfo((info: SingleUser) => {
          return {
            ...info,
            ...res,
          };
        });
      }).catch(e => console.log('CREATE USER ERROR', e));
    } else if (retryAccount) {
      const uid = newUserData?.data?.user?.id;
      if (uid && deviceId) {
        createAccount({
          account_number: getAccountNumber(),
          name: `${formData.step2.lastName}, ${formData.step2.name}`,
          status: "PENDING",
          type: "RESIDENTIAL",
        }).then(async resp => {
          onAccountSuccess(uid, resp);
        });
      }
      setRetryAccount(false);
    } else {
      setActiveStep(step => step + 1);
    }
    setSubmitting(false);
  }, [
    isAuthenticated,
    updateUser,
    setUserInfo,
    createUser,
    userInfo,
    setActiveStep,
    newUserData,
    retryAccount,
    createAccount,
    deviceId,
    onAccountSuccess,
    getAccountNumber,
    showBilling,
  ]);

  const goBackOneStep = useCallback(() => {
    if (activeStep === 0) {
      return navigate(-1);
    }

    setActiveStep(activeStep - 1);
  }, [activeStep, navigate, setActiveStep]);

  const changeStep = useCallback(
    (incomingValues?: Step1Type | Step2Type | Step3Type | DeviceStepType | PaymentType | WarrantyStepType, submit:boolean = false) => {
      const newStep = activeStep + 1;
      if (incomingValues) {
        const data = {
          ...formValues,
          [`${incomingValues.stepName}`]: incomingValues,
        };
        setFormValues(data);

        if (submit && data) {
          onSubmit(data);
        }
      }
      !submit && setActiveStep(newStep);
    },
    [activeStep, formValues, setFormValues, onSubmit]
  );

  useEffect(() => {
    if (updatedUser?.message) {
      sendErrorToast(updatedUser.message);
      return;
    }
  }, [updatedUser]);

  const stepProps = useMemo(() => ({
    changeStep,
    goBackOneStep,
    submitting,
    previousValues: formValues,
  }), [changeStep, goBackOneStep, formValues, submitting]); 

  const stepsComponents:JSX.Element[] = useMemo(() => [
    <DeviceStep {...stepProps} setDeviceId={setDeviceId} />,
    <Step1 {...stepProps} />,
    <Step2 {...stepProps} isFinalStep={userInfo?.data?.user?.answered_security_questions && !retryAccount} />,
    ...!userInfo?.data?.user?.answered_security_questions || !isAuthenticated ? [<Step3 {...stepProps} />] : [],
    ...showBilling ? [<WarrantyStep {...stepProps} />] : [],
    ...showBilling ? [<PaymentStep {...stepProps} accountId={accountId} />] : [],
  ], [userInfo, isAuthenticated, stepProps, accountId, retryAccount, showBilling]);

  // useEffect(() => {
  //   if (activeStep === Object.keys(stepsComponents).length) {
  //     setTimeout(() => navigate(routes.login.path), 5000);
  //   }
  // }, [activeStep, navigate, stepsComponents]);

  useEffect(() => {
    if (provider && userInfo) {
      const usr = userInfo?.data?.user?.Contact;
      const name = usr?.first_name ?? "";
      const lastName = usr?.last_name ?? "";
      const phoneNumber = usr?.phone ?? "";
      const address = usr?.Location?.address1 ?? "";
      const city = usr?.Location?.city ?? provider.data.provider.Contacts?.[0]?.Location?.city ?? "";
      const state = usr?.Location?.state ?? provider.data.provider.Contacts?.[0]?.Location?.state ?? "";
      const zipCode = usr?.Location?.zip ?? provider.data.provider.Contacts?.[0]?.Location?.zip ?? "";
      setFormValues((previousValues) => ({
        ...previousValues,
        step1: {
          ...previousValues.step1,
          userName: userInfo?.data?.user?.username ?? "",
        },
        step2: {
          ...previousValues.step2,
          name,
          lastName,
          phoneNumber,
          address,
          city,
          state,
          zipCode,
        },
      }));
    }
  }, [provider, userInfo]);

  return isAuthenticated === null ? null : (
    <>
      <div style={stepperContainerStyle}>
        <Stepper activeStep={activeStep}>
          {stepsComponents.map((step, index) => (
            <Step key={index}>
              <StepLabel />
            </Step>
          ))}
        </Stepper>
      </div>
      <Stack
        justifyContent="center"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        {activeStep !== stepsComponents?.length ? (
          <Box
            padding={getREMFromPX(theme.spacing * 6)}
            maxWidth={getREMFromPX(theme.spacing * 150)}
            width={"100%"}
          >
            {stepsComponents[activeStep]}
          </Box>
        ) : (
          <FinalStepScreenCustom header="Almost Done!">
            <Typography
              fontSize={getREMFromPX(20)}
              fontWeight={theme.fonts.weights.normal}
              textAlign="center"
            >
              Please verify your account by clicking on the link sent to your email.
            </Typography>
            <Button
              style={{ marginTop: getREMFromPX(40) }}
              data-testid="okButton"
              text="OK"
              type="button"
              size="medium"
              onClick={() => navigate(routes.login.path)}
            />
          </FinalStepScreenCustom>
        )}
        <EntryPointLink mt={getREMFromPX(theme.spacing * 12)} />
      </Stack>
    </>
  );
};

export default AccountSettings;
