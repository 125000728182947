import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";
import { UserUpdateBody } from "../api/interfaces/User/UserUpdateBody";

const updateUser = async (body: UserUpdateBody, userId: string) => {
  const response = await fetcher(`${endpoints.updateUser}/${userId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_HEADER}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error();
  }

  const res = await response.json();

  return res;
};

export const useUpdateUser = (
  userId: string,
  options?: UseMutationOptions<SingleUser, Error, UserUpdateBody>
) => {
  const queryClient = useQueryClient();

  return useMutation((body: UserUpdateBody) => updateUser(body, userId), {
    ...options,
    onSuccess: async (
      data: SingleUser,
      variables: UserUpdateBody,
      context: unknown
    ) => {
      await queryClient.invalidateQueries(userId);
      await queryClient.invalidateQueries([
        "userSecurityQuestions",
        "",
        userId,
      ]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
