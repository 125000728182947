import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Stack, Typography } from '@mui/material';
import { useCreditCardValidator } from 'react-creditcard-validator';
import getREMFromPX from '../../utils/getREMFromPX';
import { theme } from '../../theme';
import Label from '../Label';
import { useForm, Controller } from 'react-hook-form';
import Select from '../Select/Select';
import SelectOption from '../SelectOption';
import { selectStyle } from "../../components/ContactAndLocationEdit/Styles"
import Input from '../Input';
import Checkbox from "../../components/Checkbox";
import { endpoints } from "../../api/constants/endpoints";
import useGetThemePath from "../../hooks/useGetThemePath";
import { useUpdateAccount } from "../../hooks/useUpdateAccount";
import { useUpdateUser } from "../../hooks/useUpdateUser";
import { useGetEnums } from "../../hooks/useGetEnums";
import sendErrorToast from "../../utils/sendErrorToast";
import sendSuccessToast from "../../utils/sendSuccessToast";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import fetcher from "../../utils/fetchWrapper";

export interface CreditCardInfo {
  stepName?: string;
  cardHolderName?: string;
  cardNumber?: string;
  expirationDate?: string;
  cvc?: string;
  zipCode?: string;
  billingStreet?: string;
  billingCity?: string;
  billingState?: string;
  billingZip?: string;
};

interface Location {
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
};

interface WarrantyInfo {
  paymentMethod: string;
  maintenanceSel: boolean;
  insuranceSel: boolean;
};

interface CreditCardProps {
  children?: React.ReactElement;
  onSubmit: Function, 
  onSuccess?: Function,
  setIsValid: (isValid: boolean) => void;
  setIsLoading?: (isLoading: boolean) => void;
  previousValues: CreditCardInfo;
  disableForm?: boolean;
  accountId?: string;
  location?: Location;
  warranty?: WarrantyInfo;
};

const CreditCard = ({ previousValues, children, onSubmit, setIsValid, onSuccess, disableForm, location, warranty, ...props }: CreditCardProps) => {
  const themePath = useGetThemePath();
  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  
  const userId = userInfo?.data?.user?.id ?? "";
  const accountId = useMemo(() => props.accountId ?? userInfo?.data?.user?.Accounts?.[0]?.id ?? "", [userInfo, props.accountId]);

  const [isSameAddress, setIsSameAddress] = useState(false);
  const [cardNumberChanged, setCardNumberChanged] = useState(false);
  const [expirationDateChanged, setExpirationDateChanged] = useState(false);
  const [cvcChanged, setCvcChanged] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [cvc, setCvc] = useState("");

  const { mutateAsync: updateUser } = useUpdateUser(
    userId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error updating the user."
        ),
    }
  );

  const { mutateAsync: updateAccount } = useUpdateAccount(
    accountId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error updating the user."
        ),
    }
  );

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm<CreditCardInfo>({
    mode: "onChange",
    defaultValues: {
      ...previousValues,
    },
  });

  const {
    // getCardNumberProps,
    getExpiryDateProps,
    // getCVCProps,
    meta: { erroredInputs }
  } = useCreditCardValidator();

  // Get values for State input field
  const { data } = useGetEnums({
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("There was an error getting the states, please try again"),
  });

  const cardHolderName = watch("cardHolderName");
  const zipCode = watch("zipCode");
  const billingStreet = watch("billingStreet");
  const billingCity = watch("billingCity");
  const billingState = watch("billingState");
  const billingZip = watch("billingZip");
  
  const onSubmitHandler = async () => {
    props.setIsLoading?.(true);
    if (!accountId) {
      sendErrorToast(
        "No account ID found"
      )
      return
    }

    let arr = [];
    if(warranty?.maintenanceSel)
      arr.push("utility");
    if(warranty?.insuranceSel) 
      arr.push("vbg_insurance");

    // Separate expiration date to month and year
    const splitExp = getExpiryDateProps()?.ref?.current?.value.split("/") ?? ["", ""]
    if (!splitExp[0]) {
      return;
    }
    const body = {
      name: cardHolderName,
      cc_num: cardNumber.replaceAll(/\s/g,''),
      cc_exp: getExpiryDateProps()?.ref?.current?.value,
      cc_exp_month: parseInt(splitExp[0]),
      cc_exp_year: parseInt(splitExp[1]),
      cc_cvv: cvc,
      cc_cvc: cvc,
      cc_zip: zipCode,
      billingStreet,
      billingCity,
      billingState,
      billingZip,
      items: arr,
      is_default_payment_method: true,
    };

    const res = await fetcher(`${endpoints.account}/${accountId}/creditcardbilling`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    const paymentResponse = await res.json();

    // subscriptionId means the payment was processed
    if (paymentResponse?.data?.account?.subscriptionId) {
      sendSuccessToast("Payment was processed successfully");

      // User and Account need to be set active because payment was accepted
      await updateUser({
        status: "ACTIVE",
      });
      await updateAccount({
        status: "ACTIVE",
      })
      onSuccess?.();
      onSubmit({});
    } else {
      sendErrorToast(paymentResponse?.message);
    }

    props.setIsLoading?.(false);
  };

  const disable = !cardNumberChanged || !expirationDateChanged || !cvcChanged;

  // This will notify parent component if this form is valid or not.
  setIsValid(isValid && !disable && Object.values(erroredInputs).every(e => e === undefined));

  // Styling for the credit card input fields
  function getInputStyling(error: string | undefined) {
    return {
      "::placeholder": {
        color: theme[themePath].colors.textPrimary.placeholder,
        opacity: 1,
      },
      ":disabled": {  
        border: `1px solid ${theme[themePath].colors.lightBorder}`,
      },
      ":disabled::placeholder": {
          opacity: 1,
          color: theme[themePath].colors.textPrimary.disabled,
      },
      width: "100%",
      fontWeight: theme.fonts.weights.normal,
      fontSize: getREMFromPX(theme.spacing * 4),
      fontFamily: theme.fontFamily,
      lineHeight: getREMFromPX(theme.spacing * 6),
      color: theme[themePath].colors.textPrimary.primary,
      background: theme[themePath].colors.white,
      borderRadius: "8px",
      padding: `${getREMFromPX(theme.spacing * 3)} ${getREMFromPX(theme.spacing * 4)}`,
      border: error
        ? `1px solid ${theme[themePath].colors.lightWarningStatus}`
        : `1px solid ${theme[themePath].colors.lightBorder}`,
      "&:hover": {
        outline: error
          ? "none"
          : `1px solid ${theme[themePath].colors.primary[500]}`,
      },
      "&:focus": {
        outline: error
          ? "none"
          : `1px solid ${theme[themePath].colors.primary[500]}`,
      },
    };
  };

  // If checkbox to use same address is checked, set the values
  useEffect(() => {
    if (isSameAddress && location) {
      setValue('billingStreet', location.billingStreet, { shouldValidate: true })
      setValue('billingCity', location.billingCity, { shouldValidate: true })
      setValue('billingState', location.billingState, { shouldValidate: true })
      setValue('billingZip', location.billingZip, { shouldValidate: true })
    }
  }, [isSameAddress, location, setValue])

  // function to validate credit card numbers using the Luhn algorithm
  function validateByLuhn(cardNumber: any) {
    let sum = 0;
    let shouldDouble = false;
    for (let i = cardNumber.length - 1; i >= 0; i--) {
       let digit = parseInt(cardNumber.charAt(i));
       if (shouldDouble) { 
          if ((digit *= 2) > 9) digit = digit - 9;
       }
       sum = sum + digit;
       shouldDouble = !shouldDouble;
    }
    return sum % 10 === 0;
 }
 function validateCreditCard(event: any) {
    let cardNumber = event.target.value;
    cardNumber = cardNumber && cardNumber.trim();
    setCardNumber(cardNumber);
    setCardNumberChanged(true);
    setCvc('');
    erroredInputs.cvc = "Invalid cvc";
    let tempCardNumber = cardNumber.replaceAll(/\s/g,'')

    let isValid =
    (validateByLuhn(tempCardNumber) &&
    tempCardNumber.length === 15 &&
    (tempCardNumber.indexOf("34") === 0 || tempCardNumber.indexOf("37") === 0)) ||
    (tempCardNumber.length === 13 && tempCardNumber[0] === "4") ||
    (tempCardNumber.length === 16 &&
    (tempCardNumber[0] === "4" || tempCardNumber[0] === "6" ||
    (tempCardNumber[0] === "5" && tempCardNumber[1] >= 1 && tempCardNumber[1] <= 5)));
    if (isValid && !isNaN(+tempCardNumber)) {
      erroredInputs.cardNumber = undefined;
    } else {
      erroredInputs.cardNumber ="Invalid Card Number";
    }
 }

 function validateCVC(event: any) {
  let cvcValue = event.target.value;
  cvcValue = cvcValue && cvcValue.trim();
  setCvc(cvcValue);
  setCvcChanged(true);
  
  let isValid = false;
  if(cardNumber && cvcValue && !isNaN(+cvcValue)){
    if((cardNumber.indexOf("34") === 0 || cardNumber.indexOf("37") === 0) && cvcValue.length === 4){
      isValid = true;
    }else if(!(cardNumber.indexOf("34") === 0 || cardNumber.indexOf("37") === 0) && cvcValue.length === 3){
      isValid = true;
    }
  }
  if(isValid){
    erroredInputs.cvc = undefined;
  }else{
    erroredInputs.cvc = "Invalid cvc";
  }
}

function cc_format(value: any) {
  const v = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(" ") : value;
}
  
  return (
    <>
      <Stack>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack
          spacing={getREMFromPX(theme.spacing * 3)}
          mt={getREMFromPX(theme.spacing * 8)}
        >
          <Label htmlFor="cardHolderName">Card Holder Name</Label>
          <Input
            data-testid="cardHolderName"
            placeholder="John Doe"
            {...register("cardHolderName", { required: true })}
          />
          <Label htmlFor="cardNumber">Card Number</Label>
          <input style={getInputStyling(erroredInputs.cardNumber)} value={cc_format(cardNumber)} placeholder="0000 0000 0000 0000" onChange = {validateCreditCard} />
          <Stack direction="row" spacing={getREMFromPX(theme.spacing * 3)}>
            <Stack spacing={getREMFromPX(theme.spacing * 3)}>
              <Label htmlFor="expiryDate">Expiration</Label>
              <input style={getInputStyling(erroredInputs.expiryDate)} {...getExpiryDateProps({ onChange: () => setExpirationDateChanged(true) })} />
            </Stack>
            <Stack spacing={getREMFromPX(theme.spacing * 3)}>
              <Label htmlFor="cvc">CVC</Label>
              <input style={getInputStyling(erroredInputs.cvc)} placeholder="000" onChange = {validateCVC} value={cvc}/>
            </Stack>
            <Stack spacing={getREMFromPX(theme.spacing * 3)}>
              <Label htmlFor="zipCode">Zip Code</Label>
              <Input data-testid="zipCode" {...register("zipCode", { required: true, maxLength: 5, pattern: { value: /^\d{5}$/, message: "Invalid zip code" } })} />
            </Stack>
          </Stack>
          {!!location && (
            <Stack
              direction="row"
              alignItems="center"
              gap={getREMFromPX(theme.spacing * 4)}
              m={getREMFromPX(theme.spacing * 4)}
            >
              <Checkbox checked={isSameAddress} onChange={(e) => setIsSameAddress(e.target.checked)} />
              <Typography
                fontSize={getREMFromPX(theme.spacing * 4)}
              >
                Use location address as billing address
              </Typography>
            </Stack>
          )}
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            mt={getREMFromPX(theme.spacing * 4)}
          >
            <Label htmlFor="address">Billing Address</Label>
            <Input id="address" {...register("billingStreet", { required: true })} />
          </Stack>
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            mt={getREMFromPX(theme.spacing * 4)}
          >
            <Label htmlFor="city">City</Label>
            <Input id="city" {...register("billingCity", { required: true })} />
          </Stack>
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            mt={getREMFromPX(theme.spacing * 4)}
          >
            <Label htmlFor="billingState">State</Label>
            <Controller
              name="billingState"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => {
                return (
                <div style={selectStyle}>
                  <Select {...field} id="billingState" value={data ? field.value ?? "" : ""}>
                    {data &&
                      Object.keys(data.data.system.States_US).map((key) => (
                        <SelectOption data-testid={key} key={key} value={key}>
                          {data.data.system.States_US[key]}
                        </SelectOption>
                      ))}
                  </Select>
                </div>
              )}}
            />
          </Stack>
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            mt={getREMFromPX(theme.spacing * 4)}
          >
            <Label htmlFor="billingZip">Zip Code</Label>
            <Input {...register("billingZip", { required: true })} id="billingZip" />
          </Stack>
        </Stack>
        { children }
      </form>
      </Stack>
    </>
  );
}

export default CreditCard;