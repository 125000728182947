import * as React from "react";
import InputUnstyled, { InputUnstyledProps } from "@mui/base/InputUnstyled";
import { styled } from "@mui/system";
import {
  getDefaultBorderAndOutlineStyles,
  InputSizeEnum,
  getInputStyles,
} from "./Styles";
import getREMFromPX from "../../utils/getREMFromPX";
import { theme } from "../../theme";
import useGetThemePath from "../../hooks/useGetThemePath";

export interface InputProps extends InputUnstyledProps {
  ownerState: InputUnstyledProps & {
    error: boolean;
    softError?: boolean;
  };
}

const StyledBigSizeInputElement = styled("input")((props: InputProps) => {
  const themePath = useGetThemePath();
  return getInputStyles(themePath).concat(
    `padding: ${getREMFromPX(theme.spacing * 4)} ${getREMFromPX(
      theme.spacing * 6
    )};
`.concat(getDefaultBorderAndOutlineStyles(props, themePath))
  );
});

const StyledMediumSizeInputElement = styled("input")((props: InputProps) => {
  const themePath = useGetThemePath();
  return getInputStyles(themePath)
    .concat(
      `padding: ${getREMFromPX(theme.spacing * 3)} ${getREMFromPX(
        theme.spacing * 4
      )};
    `
    )
    .concat(getDefaultBorderAndOutlineStyles(props, themePath));
});

const decideInputSize = (size?: keyof typeof InputSizeEnum) => {
  if (size === InputSizeEnum.medium) {
    const components = {
      Input: StyledMediumSizeInputElement,
    };
    return components;
  }
  const components = {
    Input: StyledBigSizeInputElement,
  };
  return components;
};

const CustomStyledInput = (
  props: InputUnstyledProps & {
    size?: keyof typeof InputSizeEnum;
    softError?: boolean;
  },
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const Input = decideInputSize(props.size || InputSizeEnum.medium);
  return <InputUnstyled components={Input} {...props} ref={ref} />;
};

const CustomInput = React.forwardRef(CustomStyledInput);

export default CustomInput;
