import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const introAlertStyles = {
  margin: `${getREMFromPX(theme.spacing * 2)} ${getREMFromPX(
    theme.spacing * 2
  )} 0 ${getREMFromPX(theme.spacing * 2)}`,
  padding: getREMFromPX(theme.spacing * 6),
};

export const getWifiStyles = (themePath = "default") => ({
  fontSize: "32px",
  color: theme[themePath].colors.primary[500],
});

export const spinnerContainerStyles = {
  margin: "0 !important",
};

export const buttonStyles = {
  width: "100%",
};

export const findAPlanButtonStyles = {
  marginTop: getREMFromPX(theme.spacing * 6),
};

export const getAddressArrowStyles = (themePath = "default") => ({
  fill: theme[themePath].colors.textPrimary.primary,
  alignSelf: "flex-end",
});

export const buttonStylesN = {
  border: 0,
  background: "none",
};