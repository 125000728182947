import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { Account } from "../api/interfaces/Account";
import fetcher from "../utils/fetchWrapper";
import { AccountUpdateBody } from "../api/interfaces/Account/AccountUpdateBody";

const updateAccount = async (body: AccountUpdateBody, accountId: string) => {
  const response = await fetcher(`${endpoints.account}/${accountId}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_HEADER}`,
    },
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error();
  }

  const res = await response.json();

  return res;
};

export const useUpdateAccount = (
  accountId: string,
  options?: UseMutationOptions<Account, Error, AccountUpdateBody>
) => {
  const queryClient = useQueryClient();

  return useMutation((body: AccountUpdateBody) => updateAccount(body, accountId), {
    ...options,
    onSuccess: async (
      data: Account,
      variables: AccountUpdateBody,
      context: unknown
    ) => {
      await queryClient.invalidateQueries(accountId);
      await queryClient.invalidateQueries([
        "",
        "",
        accountId,
      ]);
      options?.onSuccess?.(data, variables, context);
    },
  });
};
