import {
  Divider,
  Stack,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../App";
import {
  AuthContext,
  AuthenticationContext,
} from "../../components/AuthProvider";
import Box from "../../components/Box";
import Button from "../../components/Button";
import EntryPointLink from "../../components/EntryPointLink";
import ErrorMessage from "../../components/ErrorMessage";
import Input from "../../components/Input";
import Label from "../../components/Label";
import Link from "../../components/Link";
import { useLoginUser } from "../../hooks/useLoginUser";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import sendErrorToast from "../../utils/sendErrorToast";
import { dividerStyle, headerStyle } from "./Styles";
import useViewport  from "../../hooks/useViewport";
import { useGetConfig } from "../../hooks/useGetConfig";

interface LoginFormValues {
  userName: string;
  password: string;
}

const Login = () => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm<LoginFormValues>({
    mode: "onChange",
    defaultValues: {
      userName: "",
      password: "",
    },
  });
  const location = useLocation();
  const state = location.state as { formerLocation?: string };
  const navigate = useNavigate();
  const redirectRoute = useMemo(
    () => state?.formerLocation || routes.dashboard.name,
    [state?.formerLocation]
  );
  const { data: config } = useGetConfig();

  const showCreateAccount = config?.data?.config?.enabled_features?.CREATE_ACCOUNT?.enabled;

  const {
    data,
    mutate: loginUser,
    isLoading,
  } = useLoginUser({
    onError: () =>
      sendErrorToast("There was an error during the login, please try again"),
    onSuccess: (data:any) => {
      if (data?.data?.user?.user?.status === "PENDING") {
        localStorage.setItem("token", data.data?.user?.jwt);
        navigate(routes.statusPending.name)
      }
    },
  });
  const [infoError, setInfoError] = useState(false);
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const password = watch("password").trim();
  const userName = watch("userName").trim();
  const { isMobile } = useViewport();

  const disableButton = !isValid || isLoading || infoError;

  const onSubmit = () => loginUser({ userName, password });

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectRoute);
    }
  }, [isAuthenticated, navigate, redirectRoute]);

  useEffect(() => {
    if (data === "Invalid user name / password combination.") {
      setInfoError(true);
    }
    if (
      data !== "Invalid user name / password combination." &&
      data?.data?.user?.jwt
    ) {
      localStorage.setItem("token", data.data?.user?.jwt);
      navigate(redirectRoute);
    }
  }, [data, navigate, redirectRoute]);

  useEffect(() => {
    setInfoError(false);
  }, [password, userName]);

  return isAuthenticated === null ? null : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        justifyContent="center"
        alignItems="center"
        mt={getREMFromPX(theme.spacing * 12)}
      >
        <Box
          padding={getREMFromPX(theme.spacing * 6)}
          width={ isMobile ? "-webkit-fill-available" : getREMFromPX(theme.spacing * 115) }
        >
          <Stack>
            <Typography sx={headerStyle} component="h1">
              Sign In
            </Typography>
            <Stack
              spacing={getREMFromPX(theme.spacing * 2)}
              direction="column"
              mt={getREMFromPX(theme.spacing * 8)}
            >
              <Label htmlFor="userName">Username</Label>
              <Input
                autoComplete="username"
                {...register("userName", {
                  required: true,
                })}
                error={infoError}
                disabled={isLoading}
                id="userName"
                placeholder="Username"
              />
            </Stack>
            <Stack
              spacing={getREMFromPX(theme.spacing * 2)}
              direction="column"
              mt={getREMFromPX(theme.spacing * 8)}
            >
              <Label htmlFor="password">Password</Label>
              <Input
                autoComplete="current-password"
                {...register("password", {
                  required: true,
                })}
                error={infoError}
                disabled={isLoading}
                type="password"
                id="password"
                placeholder="Password"
              />
            </Stack>
            {infoError && (
              <ErrorMessage mt={getREMFromPX(theme.spacing * 2)}>
                Wrong Username or Password.
              </ErrorMessage>
            )}
            <Stack mt={getREMFromPX(theme.spacing * 3)}>
              <Link to="/forgot-password" size="medium">
                Forgot Password?
              </Link>
            </Stack>
            <Divider sx={dividerStyle} />
          </Stack>
          <Stack
            alignItems="left"
            direction="row"
            justifyContent="space-between"
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <div>
              {showCreateAccount && (
                <>
                  <Typography>Not registered yet?</Typography>
                  <Link to="/create-account" size="large">
                    Create An Account
                  </Link>
                </>
              )}
            </div>
            <Button
              data-testid="submit_button"
              text="Sign In"
              type="submit"
              size="medium"
              disabled={disableButton}
            />
          </Stack>
        </Box>
        <EntryPointLink mt={getREMFromPX(theme.spacing * 12)} />
      </Stack>
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </form>
  );
};

export default Login;
