import { useContext } from "react";
import { Navigate } from "react-router";
import { AuthContext, AuthenticationContext } from "../AuthProvider";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import { routes } from "../../App";

const ProtectedRoute = ({
  redirectPath = "/",
  children,
}: {
  redirectPath?: string;
  children: React.ReactElement;
}) => {
  const { isAuthenticated } = useContext(AuthContext) as AuthenticationContext;
  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

  const formerLocation = {
    formerLocation: window.location.pathname,
  };

  if (isAuthenticated === null) {
    return null;
  }

  if (isAuthenticated === false) {
    return <Navigate to={redirectPath} state={formerLocation} />;
  }

  if (userInfo?.data?.user?.status === "PENDING" && formerLocation.formerLocation !== `/${routes.statusPending.name}`) {
    return <Navigate to={`/${routes.statusPending.name}`}  state={formerLocation} />
  }

  return children;
};
export default ProtectedRoute;
