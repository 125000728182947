import { useQueries } from "react-query";
import { useMemo } from "react";
import { endpoints } from "../api/constants/endpoints";
import { LivePort, Port } from "../api/interfaces/Port";
import fetcher from "../utils/fetchWrapper";

const getIsPortLive = async (port: Port): Promise<LivePort> => {
  const response = await fetcher(
    `${endpoints.isPortLive}/${port.device_id}/portinterface/${port.id}/live`
  );
  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

const useArePortsLive = (ports: Port[]) => {
  const livePorts = useQueries(
    ports.map((port) => ({
      queryKey: [port.id, "livePorts"],
      enabled: !!ports,
      refetchOnWindowFocus: false,
      queryFn: () => getIsPortLive(port),
    }))
  );
  const areLivePortsLoading = useMemo(
    () => livePorts.some((result) => result.isLoading),
    [livePorts]
  );

  const haveLivePortsFailed = useMemo(
    () => livePorts.some((result) => result.isError),
    [livePorts]
  );

  const mappedLivePorts = useMemo(
    () => livePorts.map((port) => port.data),
    [livePorts]
  );

  return {
    data: mappedLivePorts,
    isLoading: areLivePortsLoading,
    error: haveLivePortsFailed,
  };
};

export default useArePortsLive;
