import {
  Divider,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import Box from "../../components/Box";
import Button from "../../components/Button";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { dividerStyle } from "./Styles";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../components/AddressAndAccountProvider";
import FinalStepScreenCustom from "../../components/FinalStepScreen/FinalStepScreenCustom";
import { useGetBillingStatus } from "../../hooks/useGetBillingStatus";
import { PaymentType, Microdeposit } from "../../components/Payment";

const StatusPending = () => {
  const { userInfo, selectedAccountId } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  const [verificationRequired, setVerificationRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showError, setShowError] = useState(false);

  const isActive = useMemo(() => userInfo?.data?.user?.status === "ACTIVE", [userInfo]);


  const { data: billing } = useGetBillingStatus(selectedAccountId) ?? { data: null };

  useEffect(() => {
    if (billing?.data?.account?.billing_status.ach_verification_required) {
      setVerificationRequired(true);
    }
  }, [billing]);

  useEffect(() => {
    const id = setTimeout(() => setShowError(true), 3000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  const onSubmit = (payment: PaymentType) => {
  };

  const disableButton = !isValid;

  const getContent = () => {
    if (isActive) {
      return (
        <FinalStepScreenCustom delay={5} redirect="/">
          <Typography
            fontSize={getREMFromPX(24)}
            fontWeight={theme.fonts.weights.mediumBold}
            textAlign="center"
          >
            Your payment was verified. You are now being redirected to your dashboard. 
          </Typography>
        </FinalStepScreenCustom>
      );
    };

    if (verificationRequired) {
      return (
        <Microdeposit
          onSubmit={onSubmit}
          setIsValid={setIsValid}
          previousValues={{}}
          setIsLoading={setIsLoading}>
          <Divider sx={dividerStyle} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <Button
              data-testid="continueButton"
              text="Save"
              type="submit"
              size="medium"
              isLoading={isLoading}
              disabled={disableButton || isLoading}
            />
          </Stack>
        </Microdeposit>
      );
    };

    if (showError) {
      return (
        <Typography color="#C80000" fontSize="16px" fontWeight="600">
          Something went wrong while activating your account. Please contact your operator.
        </Typography>
      );
    };

    return (
      <CircularProgress data-testid="progressSpinner" color="inherit" />
    )
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      mt={getREMFromPX(theme.spacing * 6)}
    >
      <Box
          padding={getREMFromPX(theme.spacing * 6)}
          maxWidth={getREMFromPX(theme.spacing * 150)}
        >
        {getContent()}
      </Box>
    </Stack>
  );
};

export default StatusPending;
