import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import decodeJWT from "../../utils/decodeJWT";
import Cookies from "js-cookie";
import sendErrorToast from "../../utils/sendErrorToast";

export type AuthenticationContext = {
  isAuthenticated: null | boolean;
  logOut: () => void;
};

export const AuthContext = React.createContext<AuthenticationContext | null>(
  null
);

const AuthProvider = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const { pathname } = location;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [showToast, setShowToast] = useState(false);
  const hasLoggedRef = useRef(false);

  const logOut = useCallback(() => {
    localStorage.removeItem("token");
    Cookies.remove("jwt");
    hasLoggedRef.current = false;
    setIsAuthenticated(false);
  }, []);

  const value = useMemo(
    () => ({
      isAuthenticated,
      logOut,
    }),
    [isAuthenticated, logOut]
  );

  useEffect(() => {
    if (showToast) {
      sendErrorToast("Your session has expired, please log in again");
      setShowToast(false);
    }
  }, [showToast]);

  useEffect(() => {
    if (isAuthenticated === false && hasLoggedRef.current) {
      setShowToast(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      setIsAuthenticated(false);
      return;
    }
    const decodedToken = decodeJWT(token);

    if (!decodedToken) {
      setIsAuthenticated(false);
      return;
    }

    const isExpired =
      decodedToken?.exp && Date.now() >= decodedToken?.exp * 1000;

    if (isExpired) {
      setIsAuthenticated(false);
      setShowToast(true);
      Cookies.remove("jwt");
      localStorage.removeItem("token");
      return;
    }
    setIsAuthenticated(true);
    setShowToast(false);
    hasLoggedRef.current = true;
  }, [pathname]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;
