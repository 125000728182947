import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { SingleUser } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

const getUser = async (userId: string) => {
  const response = await fetcher(
    `${endpoints.getUser}/${userId}?loading=Contact.Location,Subscriptions,Accounts.Devices.Physicallocation,Review.Subscriptionhistory.Serviceplan,Review.Subscriptionhistory.Provider,Subscriptionhistory`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetUser = (
  userId: string,
  options?: UseQueryOptions<SingleUser, Error, SingleUser, Array<string>>
): UseQueryResult<SingleUser, Error> =>
  useQuery(["userInfo", userId], () => getUser(userId), options);
