import React, { useEffect, useMemo, useState } from "react";
import { ProviderInfo } from "../../api/interfaces/Provider";
import { SingleUser } from "../../api/interfaces/User";
import { useGetProvider } from "../../hooks/useGetProvider";
import { useGetUser } from "../../hooks/useGetUser";
import useGetUserId from "../../hooks/useGetUserId";
import sendErrorToast from "../../utils/sendErrorToast";
import { MenuItemType } from "../Layout/components/AppBar";

type AccountAndAddressType = MenuItemType & {
  accountType: string;
  accountId: string;
  deviceId: string;
};

export type AddressAndAccountContextType = {
  userInfo?: SingleUser;
  setUserInfo: Function;
  refetchUser: Function;
  provider?: ProviderInfo;
  addresses: AccountAndAddressType[];
  selectedAddress: string;
  isLoading: boolean;
  selectedAccount: string;
  selectedAccountId: string;
  selectedDeviceId: string;
};

export const AddressAndAccountContext = React.createContext<
  AddressAndAccountContextType | {}
>({});

const AddressAndAccountContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const userId = useGetUserId();

  const { data: userInfoData, isLoading: isUserLoading, refetch: refetchUser } = useGetUser(userId, {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    onError: () =>
      sendErrorToast("Sorry, there was a problem retrieving your user info"),
  });

  const { data: provider, isLoading: isProviderLoading } = useGetProvider({
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onError: () =>
      sendErrorToast("Sorry, there was a problem retrieving the city info"),
  });

  const [userInfo, setUserInfo] = useState<SingleUser>();
  const [addresses, setAddresses] = useState<AccountAndAddressType[]>([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedAccountId, setSelectedAccountId] = useState("");
  const [selectedDeviceId, setSelectedDeviceId] = useState("");

  const isLoading = isUserLoading || isProviderLoading;

  useEffect(() => {
    setUserInfo(userInfoData);
  }, [userInfoData])

  const value = useMemo(
    () => ({
      userInfo,
      setUserInfo,
      refetchUser,
      provider,
      selectedAccountId,
      addresses,
      selectedAddress,
      isLoading,
      selectedAccount,
      selectedDeviceId,
    }),
    [
      addresses,
      isLoading,
      selectedDeviceId,
      provider,
      selectedAccount,
      selectedAddress,
      userInfo,
      selectedAccountId,
      refetchUser,
    ]
  );

  useEffect(() => {
    if (userInfo) {
      const availableAddresses = userInfo.data?.user?.Accounts?.map((account) =>
        account?.Devices?.map((device) => ({
          accountId: account.id,
          accountType: account.type,
          deviceId: device.id,
          label: `${account.name} - ${device.Physicallocation.house_number} ${device.Physicallocation.street}`,
          onClick: () => {
            localStorage.setItem("selectedAccount", device.id);
            setSelectedAddress(
              `${account.name} - ${device.Physicallocation.house_number} ${device.Physicallocation.street}`
            );
            setSelectedAccount(account.type);
            setSelectedAccountId(account.id);
            setSelectedDeviceId(device.id);
          },
        }))
      ).flat() as unknown as AccountAndAddressType[];

      if (availableAddresses) {
        setAddresses(availableAddresses);
        const savedDeviceId = localStorage.getItem("selectedAccount");
        const previouslySelectedAddress = availableAddresses.find(
          (address) => address.deviceId === savedDeviceId
        );
        if (previouslySelectedAddress) {
          setSelectedAddress(previouslySelectedAddress.label);
          setSelectedAccount(previouslySelectedAddress.accountType);
          setSelectedAccountId(previouslySelectedAddress.accountId);
          setSelectedDeviceId(previouslySelectedAddress.deviceId);
          return;
        }
        
        const selAddress = availableAddresses[0]?.label || "";
        const selAccount = availableAddresses[0]?.accountType || "";
        const selDeviceId = availableAddresses[0]?.deviceId || "";
        setSelectedAddress(selAddress);
        setSelectedAccount(selAccount);
        setSelectedAccountId(availableAddresses[0]?.accountId || "");
        setSelectedDeviceId(selDeviceId);
        localStorage.setItem("selectedAccount", selDeviceId);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (provider) {
      document.title = provider.data.provider.name;
    }
  }, [provider]);

  return (
    <AddressAndAccountContext.Provider value={value}>
      {children}
    </AddressAndAccountContext.Provider>
  );
};
export default AddressAndAccountContextProvider;
