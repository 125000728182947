import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { ProviderInfo } from "../api/interfaces/Provider";
import fetcher from "../utils/fetchWrapper";

const getProvider = async () => {
  const response = await fetcher(
    `${endpoints.getProvider}?loading=Contacts.Location`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetProvider = (
  options?: UseQueryOptions<ProviderInfo, Error, ProviderInfo, Array<string>>
): UseQueryResult<ProviderInfo, Error> =>
  useQuery(["provider"], getProvider, options);
