import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import fetcher from "../utils/fetchWrapper";
import { endpoints } from "../api/constants/endpoints";
import { ContactAndLocationInfo } from "../components/ContactAndLocationEdit/ContactAndLocationEdit";

const updateContactInformation = async (
  data: ContactAndLocationInfo,
  contactId: string,
  locationId: string
) => {
  const contactResponse = await fetcher(
    `${endpoints.updateContact}/${contactId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        last_name: data.lastName,
        first_name: data.name,
        phone: data.phoneNumber,
      }),
    }
  );

  if (!contactResponse.ok) {
    throw new Error();
  }

  const locationResponse = await fetcher(
    `${endpoints.updateLocation}/${locationId}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        address1: data.address,
        city: data.city,
        state: data.state,
        zip: data.zipCode,
      }),
    }
  );

  if (!locationResponse.ok) {
    throw new Error();
  }

  const contactJson = await contactResponse.json();
  const locationJson = await locationResponse.json();

  return {
    ...contactJson,
    Location: locationJson,
  };
};

export const useUpdateContactInformation = (
  userId: string,
  options?: UseMutationOptions<
    any,
    Error,
    { data: ContactAndLocationInfo; contactId: string; locationId: string }
  >
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ data, contactId, locationId }) =>
      updateContactInformation(data, contactId, locationId),
    {
      ...options,
      onSuccess: async (data: any, variables: any, context: unknown) => {
        await queryClient.invalidateQueries(userId);
        options?.onSuccess?.(data, variables, context);
      },
    }
  );
};
