const CityIcon = ({ ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img
    {...props}
    src={`${process.env.PUBLIC_URL}/assets/provider/${process.env.REACT_APP_GUID}.png`}
    //src={require(`../../assets/provider/${process.env.REACT_APP_GUID}.png`)}
    alt="city_logo"
    style={{width:'7rem', height:'4rem'}}
  />
);
export default CityIcon;
