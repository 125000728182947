import { useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Typography } from "@mui/material";
import Box from "../Box";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { getIconStyle } from "./Styles";
import useGetThemePath from "../../hooks/useGetThemePath";
import { useNavigate } from "react-router-dom";

const FinalStepScreenCustom = ({
  children,
  header = "",
  delay = 5,
  redirect,
}: { 
  children?: React.ReactElement[] | React.ReactElement,
  header?: string,
  delay?: number,
  redirect?: string,
}) => {
  const navigate = useNavigate();
  const themePath = useGetThemePath();

  useEffect(() => {
    const id = setTimeout(() => redirect && navigate(redirect), delay * 1000);

    return () => {
      clearTimeout(id);
    };
  }, [navigate, delay, redirect]);

  return (
    <Box
      padding={getREMFromPX(theme.spacing * 13)}
      m="0 auto"
      maxWidth={getREMFromPX(600)}
    >
      <Stack alignItems="center" justifyContent="center">
        <FontAwesomeIcon style={getIconStyle(themePath)} icon="thumbs-up" />
        <Typography
          fontSize={getREMFromPX(64)}
          fontWeight={theme.fonts.weights.bolder}
          component="h1"
          marginBottom={getREMFromPX(theme.spacing * 8)}
        >
          {header}
        </Typography>
        {children}
      </Stack>
    </Box>
  );
};
export default FinalStepScreenCustom;
