import { useState, useMemo, useContext, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useUpdateUsername } from "../../../../hooks/useUpdateUsername";
import { useValidateUser } from "../../../../hooks/useValidateUser";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import useGetUserId from "../../../../hooks/useGetUserId";
import Button from "../../../../components/Button/Button";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input/Input";
import ErrorMessage from "../../../../components/ErrorMessage";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import getREMFromPX from "../../../../utils/getREMFromPX";
import sendErrorToast from "../../../../utils/sendErrorToast";
import isValidEmail from "../../../../utils/isValidEmail";
import { theme } from "../../../../theme";
import { getHeaderIconStyle, getHeaderStyle, getListValueStyle } from "./Styles";
import { getEditStyle } from "../Contact/Styles";
import sendSuccessToast from "../../../../utils/sendSuccessToast";
import { SingleUser } from "../../../../api/interfaces/User";

export type UsernameType = {
  email: string;
};

export const Username = () => {
  const userId = useGetUserId();
  const { userInfo, setUserInfo } = useContext(AddressAndAccountContext) as AddressAndAccountContextType;
  const contactId = useMemo(() => userInfo?.data?.user?.Contact?.id ?? "", [userInfo]);
  const themePath = useGetThemePath();

  const [isEditing, setIsEditing] = useState(false);

  const username = useMemo(() => userInfo?.data?.user?.username, [userInfo])

  const {
    watch,
    register,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<UsernameType>({
    mode: "onChange",
    defaultValues: useMemo(() => ({ email: username ?? "" }), [username]),
  });

  const email = watch("email").trim();

  const {
    mutateAsync: updateUsername,
    data: usernameData,
    isLoading: usernameLoading,
  } = useUpdateUsername(userId, contactId, {
    onSuccess: () => {
      sendSuccessToast("Username saved");
    },
    onError: () =>
      sendErrorToast(
        "There was a problem validating the Username, please try again"
      ),
  });

  const {
    refetch: validateUserName,
    data: userIsValid,
    isLoading: validateLoading,
  } = useValidateUser(email, {
    cacheTime: 0,
    onError: () =>
      sendErrorToast(
        "There was a problem updating the Username, please try again"
      ),
    enabled: false,
  });

  useEffect(() => {
    if (usernameData) {
      setUserInfo((info: SingleUser) => {
        return {
          ...info,
          ...usernameData,
          data: {
            ...info?.data,
            user: {
              ...info?.data?.user,
              ...usernameData?.data?.user,
              Contact: {
                ...info?.data?.user?.Contact,
                email: usernameData?.data?.user?.username,
              }
            },
          },
        };
      });
      setIsEditing(false);
    }
  }, [usernameData, setUserInfo])

  useEffect(() => {
    if (userIsValid?.data.user.available === false) {
      setError("email", {
        message: "That username is already taken. Please choose a new one",
      });
    } else if (userIsValid?.data.user.available === true) {
      updateUsername({ email: email });
    }
  }, [userIsValid, setError, email, updateUsername]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const onSubmitHandler = useCallback(async (e:UsernameType) => {
    if (email === username) {
      setIsEditing(false);
    } else {
      await validateUserName();
    }
  }, [email, username, validateUserName]);

  const isLoading = usernameLoading || validateLoading;

  const disableButton =
    !isValid || !!Object.values(errors).length || validateLoading;

  const disableFields = validateLoading;

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={`0 0 ${getREMFromPX(theme.spacing * 3)} 0`}
      >
        <Stack direction="row" alignItems="center">
          <PersonIcon sx={getHeaderIconStyle(themePath)} />
          <Typography sx={getHeaderStyle}>
            {!isEditing ? "User" : "Edit User"}
          </Typography>
        </Stack>
        {!isEditing && (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleEdit}
            sx={getEditStyle(themePath)}
            component="button"
          >
            <Typography component="span"> Edit </Typography>
            <FontAwesomeIcon icon="pencil" />
          </Stack>
        )}
      </Stack>
      {isEditing ? (
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          data-testid="edit-username"
        >
          <Stack
            spacing={getREMFromPX(theme.spacing * 2)}
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <Label htmlFor="userName">Username</Label>
            <Input
              placeholder="Eg: example@example.com"
              autoComplete="username"
              disabled={disableFields}
              error={!!errors.email && !!email.length}
              id="userName"
              {...register("email", {
                required: true,
                validate: (value) => isValidEmail(value),
              })}
            />
          </Stack>
          <Typography
            mt={getREMFromPX(theme.spacing * 3)}
            fontSize={getREMFromPX(theme.spacing * 2.5)}
          >
            Username must be an email and less than 80 characters.
          </Typography>
          {errors.email && email && (
            <ErrorMessage fontSize={getREMFromPX(theme.spacing * 2.5)}>
              {errors.email?.message ||
                "Invalid Username. Please enter a new one."}
            </ErrorMessage>
          )}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            paddingTop={getREMFromPX(theme.spacing * 8)}
          >
            <Button
              data-testid="cancelButton"
              text="Cancel"
              type="button"
              size="large"
              variant="text"
              onClick={() => setIsEditing(false)}
            />
            <Button
              data-testid="saveButton"
              text="Save"
              type="submit"
              size="large"
              disabled={disableButton}
            />
          </Stack>
        </form>
      ) : (
        <Stack
          direction="row"
          padding={`${getREMFromPX(theme.spacing * 6)} 0 0 0`}
        >
          <Typography>Username</Typography>
          <Typography sx={getListValueStyle(themePath)}>
            {username}
          </Typography>
        </Stack>
      )}
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </Stack>
  );
};
