import React from "react";

const useViewport = () => {
    const breakpoint = 620;
    const [width, setWidth] = React.useState(window.innerWidth);
  
    React.useEffect(() => {
      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);
  
    // Return the width so we can use it in our components
    return { width, breakpoint, isMobile: width < breakpoint ? true : false };
  }

export default useViewport;