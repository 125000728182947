import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography, Dialog } from "@mui/material";
import SubHeader from "../../../../components/SubHeader";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import {
  boxStyles,
  subHeaderContainer,
  getEditStyle,
} from "./Styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Rating from "../../../../components/Rating";
import ReviewForm from "../../../../components/Review";
import { Review } from "../../../../api/interfaces/Review";

const ReviewBox = ({
  header,
  headerIcon,
  review,
}: {
  header: string;
  headerIcon: IconProp;
  review: Review;
}) => {
  const themePath = useGetThemePath();

  const [showForm, setShowForm] = useState(false);

  const editButton = (
    <Stack
      direction="row"
      alignItems="center"
      alignSelf="flex-end"
      onClick={() => setShowForm(true)}
      sx={getEditStyle(themePath)}
      component="button"
    >
      <Typography component="span"> Edit </Typography>
      <FontAwesomeIcon icon="pencil" />
    </Stack>
  );

  const plan = review.Subscriptionhistory?.Serviceplan

  return (
    <>
      <Box style={boxStyles}>
        <Stack padding={getREMFromPX(theme.spacing * 6)} maxWidth={getREMFromPX(theme.spacing * 75)}>
          <div style={subHeaderContainer}>
            <SubHeader
              subHeaderFontSize={getREMFromPX(theme.spacing * 4.5)}
              icon={headerIcon}
              headerText={header}
            />
          </div>
          <Stack alignItems="top" justifyContent="center">
            <Stack flexDirection="row">
              <Typography>{plan?.name}</Typography>
            </Stack>
            <Rating value={Number(review.rating)} planId={plan?.id} planName={plan?.name} readOnly={true} fontSize={getREMFromPX(theme.spacing * 5)} />
            <Typography sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}>
              {review.comment}
            </Typography>
          </Stack>
          {editButton}
        </Stack>
      </Box>
      {plan && (
        <Dialog open={showForm} onClose={() => setShowForm(false)}>
          <Box
            padding={getREMFromPX(theme.spacing * 8)}
            maxWidth={getREMFromPX(theme.spacing * 150)}
            data-testid="dialogWrapper"
          >
            <ReviewForm
              review={review}
              history={review.Subscriptionhistory}
              providerName={header}
              planName={plan.name}
              onSuccess={() => setShowForm(false)}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default ReviewBox;
