import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import fetcher from "../utils/fetchWrapper";

interface BillingStatus {
  ach_verification_required: Boolean;
}

interface BillingStatusData {
  data: {
    account: {
      billing_status: BillingStatus;
    }
  };
  duration: number;
  messages: [];
}


const getBillingStatus = async (accountId: string) => {
  const response = await fetcher(
    `${endpoints.account}/${accountId}/billingStatus`
  );

  if (!response.ok) {
    throw new Error();
  }
  return await response.json();
};

export const useGetBillingStatus = (
  accountId: string,
  options?: UseQueryOptions<BillingStatusData, Error, BillingStatusData, Array<string>>
): UseQueryResult<BillingStatusData, Error> =>
  useQuery(["billingStatus", accountId], () => getBillingStatus(accountId), options);
