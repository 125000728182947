import {
  Divider,
  Stack,
  Typography,
  List,
  ListItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "../../../../components/Button";
import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type, Step3Type, WarrantyStepType } from "../../AccountSettings";
import {
  headerStyle,
  dividerStyle,
  getSubHeaderStyle,
  iconStyle,
  paymentSectionStyles,
} from "./Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox from "../../../../components/Checkbox";
import ExternalLink from "../../../../components/ExternalLink";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { useGetConfig } from "../../../../hooks/useGetConfig";

interface WarrantyStepProps {
  changeStep: (args: WarrantyStepType, submit: boolean) => void;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
    warrantyStep: WarrantyStepType;
  };
  goBackOneStep: () => void;
}

const WarrantyStep = ({ changeStep, previousValues, goBackOneStep }: WarrantyStepProps) => {

  const maintainceCharges = 30.00;
  const insuranceCharges = 5.00;
  const ccProcessingCharges = 1.50;
  const replacementCharges = 350.00;
  const themePath = useGetThemePath();
  const [maintenanceSel, setMaintenanceSel] = useState(previousValues.warrantyStep.maintenanceSel); //useState(previousValues.step2.checked);
  const [insuranceSel, setInsuranceSel] = useState(previousValues.warrantyStep.insuranceSel);
  const [paymentMethod, setPaymentMethod] = useState(previousValues.warrantyStep.paymentMethod);
  const [withoutInsurance, setWithoutInsurance] = useState(previousValues.warrantyStep.withoutInsurance);
  const [processingFeeSel, setProcessingFeeSel] = useState(previousValues.warrantyStep.processingFeeSel);
  const [subtotal, setSubtotal] = useState(previousValues.warrantyStep.subtotal);
  const processingFeeRef = useRef<HTMLInputElement>(null);
  const [isValid, setIsValid] = useState(false);

  const disableButton = !isValid;
  
  const { provider } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;
  
  const { data: config } = useGetConfig();
  const showInsurance = config?.data?.config?.enabled_features?.VBG_INSURANCE?.enabled;
  const showACH = config?.data?.config?.enabled_features?.ACH_PAYMENT?.enabled;

  const operatorName = provider?.data?.provider?.name;
  const onSubmit = () => {
    const data = {maintenanceSel, insuranceSel, paymentMethod, processingFeeSel, withoutInsurance, 
      maintainceCharges, insuranceCharges, ccProcessingCharges, subtotal, stepName:'warrantyStep' }
    changeStep({ ...data }, false);
  };

  const handleMaintenaceChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setMaintenanceSel(event.target.checked);
    setSubtotal(event.target.checked ? subtotal + maintainceCharges : subtotal - maintainceCharges);
  }

  const handleInsuranceChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setInsuranceSel(event.target.checked);
    setSubtotal(event.target.checked ? subtotal + insuranceCharges : subtotal - insuranceCharges);
  }
  const handleProcessingFeeChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    if(paymentMethod === 'ACH'){
      setProcessingFeeSel(false);
    }else{
      setProcessingFeeSel(event.target.checked);
      setSubtotal(event.target.checked ? subtotal + ccProcessingCharges : subtotal - ccProcessingCharges);
    }
  }

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    setPaymentMethod(event.target.value);
    if(event.target.value === 'ACH' && processingFeeRef.current?.checked){
      setSubtotal(subtotal - ccProcessingCharges);
      setProcessingFeeSel(false);
    }
  }

  useEffect(() => {
    if(maintenanceSel && paymentMethod ){
      if(paymentMethod === 'ACH' && (insuranceSel || withoutInsurance || !showInsurance)){
        setIsValid(true);
      }else if(paymentMethod === 'CC' && processingFeeSel && (insuranceSel || withoutInsurance || !showInsurance)){
        setIsValid(true);
      }else{
        setIsValid(false);
      }
    }else{
      setIsValid(false);
    }
  }, [maintenanceSel, paymentMethod, insuranceSel, withoutInsurance, processingFeeSel, showInsurance])

  return (
    <>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          Payment
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          gap={getREMFromPX(theme.spacing * 8)}
          borderRadius="20px"
          style={paymentSectionStyles}
          paddingY={getREMFromPX(theme.spacing * 8)}
          paddingX={getREMFromPX(theme.spacing * 4)}
          flexWrap="wrap"
        >
          <Checkbox
            id="maintenanceSel"
            checked={maintenanceSel}
            onChange={handleMaintenaceChange}
          />
          <Stack direction="row" justifyContent="space-between" width="80%">
            <Typography>
              {`$${maintainceCharges.toFixed(2)}/month - Operations & Maintenance`} 
            </Typography>
            <Tooltip 
              title={`$${maintainceCharges.toFixed(2)}/month - Operations & Maintenance paid to ${operatorName} for maintaining the 
                network and the open access system to multiple service providers. Additional costs will 
                be paid to the service providers you choose for each additional service such as internet`} 
              enterTouchDelay={0} arrow>
              <Stack gap={getREMFromPX(theme.spacing * 2)} flexDirection="row">
                <FontAwesomeIcon icon="circle-info" />
                <Typography
                  color={theme[themePath].colors.textPrimary.secondary}
                  fontSize={getREMFromPX(theme.spacing * 3)}
                >
                </Typography>
              </Stack>
            </Tooltip>              
          </Stack>
        </Stack>
        {showInsurance && (
          <Stack
            direction="column"
            borderRadius="20px"
            style={paymentSectionStyles}
          >
            <Stack 
              direction="row"
              width="100%"
              gap={getREMFromPX(theme.spacing * 8)}
              paddingY={getREMFromPX(theme.spacing * 8)}
              paddingX={getREMFromPX(theme.spacing * 4)}
              flexWrap="wrap"
            >
              <Checkbox
                id="insuranceSel"
                checked={insuranceSel}
                onChange={handleInsuranceChange}
              />
              <Stack direction="row" justifyContent="space-between" width="80%">
                <Typography>
                  {`$${insuranceCharges.toFixed(2)}/month - Device Insurance`}
                </Typography>
                <Tooltip 
                  title={`$${insuranceCharges.toFixed(2)}/month - Device Insurance is provided by EntryPoint Networks Inc`}
                  enterTouchDelay={0} arrow>
                  <Stack gap={getREMFromPX(theme.spacing * 2)} flexDirection="row">
                    <FontAwesomeIcon icon="circle-info" />
                    <Typography
                      color={theme[themePath].colors.textPrimary.secondary}
                      fontSize={getREMFromPX(theme.spacing * 3)}
                    >
                    </Typography>
                  </Stack>
                </Tooltip>              
              </Stack>
            </Stack>
            <Stack direction="column">
              <Typography 
                paddingX={getREMFromPX(theme.spacing * 4)}
              >
                <pre style={{ fontFamily: 'inherit', margin: '0', whiteSpace: 'pre-wrap'}}>
                  Get accidental damage protection and support for your in-home device (we call it a VBG - Virtual Broadband Gateway)
                </pre>
                <List sx={{ listStyleType: 'disc', pl: 4, '& .MuiListItem-root': {display: 'list-item',}, }}>
                  <ListItem sx={{ paddingY: "0"}}>Unlimited Repairs for accidental damage</ListItem>
                  <ListItem sx={{ paddingY: "0"}}>Overnight shipment to replace broken device</ListItem>
                  <ListItem sx={{ paddingY: "0"}}>Coverage for life of the device</ListItem>
                </List>
              </Typography>
              <Typography 
                paddingX={getREMFromPX(theme.spacing * 4)}
                >
                  {"Complete Insurance Terms -> "}
                  <ExternalLink
                    href="https://entpnt.com/"
                    size="medium"
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  > {"view"}
                  </ExternalLink>
              </Typography>
              <Typography 
                paddingX={getREMFromPX(theme.spacing * 4)}
                paddingY={getREMFromPX(theme.spacing * 4)}
                fontWeight={theme.fonts.weights.bolder}
                >
                <pre style={{ fontFamily: 'inherit', margin: '0', whiteSpace: 'pre-wrap'}}>
                  NOTE: Without device insurance, you will be responsible for the full 
                  replacement cost of 
                  <span 
                  style={{color: 'red', paddingLeft: '5px'}}
                  >{`$${replacementCharges.toFixed(2)}`}</span>
                </pre>
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
      <Stack 
        padding={getREMFromPX(theme.spacing * 4)}
        alignItems="center"
        direction="row"
        justifyContent="space-evenly"
      >
        <Typography sx={getSubHeaderStyle(themePath)}>
          Choose a Payment Method
        </Typography> 
        <Tooltip 
          title="All monthly payments will automatically be deducted on the first of each month. 
            First month is prorated except for the transaction fee (cc only)"
          enterTouchDelay={0} arrow>
          <Stack gap={getREMFromPX(theme.spacing * 2)} flexDirection="row">
            <FontAwesomeIcon icon="circle-info" />
            <Typography
              color={theme[themePath].colors.textPrimary.secondary}
              fontSize={getREMFromPX(theme.spacing * 3)}
            >
            </Typography>
          </Stack>
        </Tooltip>   
      </Stack>  
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-evenly"
        mt={getREMFromPX(theme.spacing * 6)}
      >
        <FormControl>
          <RadioGroup 
            id="paymentMethod"
            name="paymentMethod"
            value={paymentMethod}
            onChange={handlePaymentMethodChange}
            row
          >
            <Button text="" style={{marginRight: getREMFromPX(theme.spacing * 8)}}>
              <FormControlLabel  control={<Radio size='medium' color='secondary' /> } label="Credit Card" value="CC"/>
            </Button>
            {showACH && (
              <Button text="" style={{marginLeft: getREMFromPX(theme.spacing * 8)}}>
                <FormControlLabel  control={<Radio size='medium' color='secondary' />} label="ACH" value="ACH"/>
              </Button>
            )}
          </RadioGroup>
        </FormControl>
      </Stack> 
      {paymentMethod === 'CC' && (
        <Stack
          direction="row"
          alignItems="center"
          gap={getREMFromPX(theme.spacing * 8)}
          marginTop={getREMFromPX(theme.spacing * 8)}
          paddingX={getREMFromPX(theme.spacing * 4)}
        >
          <Checkbox
            id="processingFeeSel"
            checked={processingFeeSel}
            onChange={handleProcessingFeeChange}
            ref={processingFeeRef}
          />
          <Typography>
            {`I acknowledge that a $${ccProcessingCharges.toFixed(2)} processing fee will be added when using a credit card.`}
          </Typography>
          </Stack>
        )}
        {!insuranceSel && showInsurance && (
          <Stack
            direction="column"
            alignItems="center"
            marginTop={getREMFromPX(theme.spacing * 8)}
          >
            <Stack direction="column">
              <Typography>
                Warning: You have chosen to continue without insuring your device.
              </Typography>
            </Stack>
            <Stack 
              direction="row"
              gap={getREMFromPX(theme.spacing * 8)}
              paddingX={getREMFromPX(theme.spacing * 4)}
            >
              <Checkbox
                id="withoutInsurance"
                checked={withoutInsurance}
                onChange={(e) => setWithoutInsurance(e.target.checked)}
              />
              <Typography>
                I acknowledge that I am opting out of device insurance. I understand that I am 
                responsible for the full cost of replacement for my device
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack 
          padding={getREMFromPX(theme.spacing * 4)}
          alignItems="center"
        >
          <Typography sx={getSubHeaderStyle(themePath)}>
            Subtotal: {`$${subtotal.toFixed(2)}`} 
          </Typography>   
        </Stack>  
        <>
          <Divider sx={dividerStyle} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <Button
              startIcon={
                <FontAwesomeIcon icon="angle-left" style={iconStyle} />
              }
              onClick={goBackOneStep}
              variant="text"
              text="Back"
              size="medium"
            />
            <Button
              data-testid="continueButton"
              text="Continue"
              type="submit"
              size="medium"
              onClick={onSubmit}
              disabled={disableButton}
            />
          </Stack>
        </>
      {/* <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop> */}
    </>
  );
};
export default WarrantyStep;
