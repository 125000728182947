import { useState } from "react";
import { Stack } from "@mui/material";
import Input from "../../components/Input";
import { useNavigate } from "react-router-dom";
import sendErrorToast from "../../utils/sendErrorToast";
import fetcher from "../../utils/fetchWrapper";
import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

const PrivateAccess = () => {
  const navigate = useNavigate();

  const [accessCode, setAccessCode] = useState("");

  const submit = async () => {
    const loading = "Servicetype,Provider.Contacts,Plans";
    const response = await fetcher(`${process.env.REACT_APP_BE_URL}/service/private/${accessCode}?loading=${loading}`);
    if (!response.ok) {
      sendErrorToast("There was an error fetching the private service");
    }
    const parsed = await response.json();
    const service = parsed?.data?.service;

    if (service?.servicetype_id) {
      navigate(`/services/${service.servicetype_id}/plans`, { state: service })
    }
  }

  const handleKeyDown = (event:any) => {
    if (event.key === 'Enter') {
      submit()
    }
  }

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="flex-end">
      <Input
        placeholder="Private Code"
        id="privateAccess"
        style={{ width: 200, marginRight: getREMFromPX(theme.spacing * 5) }}
        onChange={e => setAccessCode(e.target.value)}
        value={accessCode}
        onKeyDown={handleKeyDown}
      />
    </Stack>
  );
};
export default PrivateAccess;
