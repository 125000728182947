import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";

export const getHeaderStyles = (themePath = "default") => `
height: ${getREMFromPX(theme.spacing * 16)};
background-color:${theme[themePath].colors.white};
padding: ${getREMFromPX(theme.spacing * 2)} 0;
display: flex;
align-items: center;
`;

export const headerLogoImageStyles = {
  width: getREMFromPX(theme.spacing * 28.25),
  height: "auto",
};
