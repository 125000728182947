import { theme } from "../../theme";
import getREMFromPX from "../../utils/getREMFromPX";
import { InputProps } from "./Input";

export enum InputSizeEnum {
  medium = "medium",
  large = "large",
}

export const getDefaultBorderAndOutlineStyles = (
  props: InputProps,
  themePath = "default"
) => `border: ${
  props.ownerState.error
    ? `1px solid ${theme[themePath].colors.lightWarningStatus}`
    : `1px solid ${theme[themePath].colors.lightBorder}`
};
&:hover {
  outline:${
    props.ownerState.error || props.ownerState.softError
      ? `1px solid ${theme[themePath].colors.lightWarningStatus}`
      : `1px solid ${theme[themePath].colors.primary[500]}`
  }
  }
  &:focus {
    outline: ${
      props.ownerState.error || props.ownerState.softError
        ? `none`
        : `1px solid ${theme[themePath].colors.primary[500]}`
    };
  }`;

export const getInputStyles = (themePath = "default") => `
::-webkit-outer-spin-button,
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
};
-moz-appearance: textfield;
::placeholder {
   color: ${theme[themePath].colors.textPrimary.placeholder};
   opacity: 1;
}
:disabled {  
   border: 1px solid ${theme[themePath].colors.lightBorder};
}
:disabled::placeholder {
    opacity: 1;
    color: ${theme[themePath].colors.textPrimary.disabled};
}
width: 100%;
font-weight: ${theme.fonts.weights.normal};
font-size: ${getREMFromPX(theme.spacing * 4)};
font-family: ${theme.fontFamily};
font-weight: 400;
line-height: ${getREMFromPX(theme.spacing * 6)};
color: ${theme[themePath].colors.textPrimary.primary};
background: ${theme[themePath].colors.white};
border-radius: 8px;
`;
