import useGetThemePath from "../../../../hooks/useGetThemePath";
import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  getHeaderIconStyle,
  getHeaderStyle,
  getListValueStyle,
} from "./Styles";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { theme } from "../../../../theme";
import { getEditStyle } from "../Contact/Styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGetUsersSecurityQuestions } from "../../../../hooks/useGetSecurityQuestions";
import sendErrorToast from "../../../../utils/sendErrorToast";
import useGetUserId from "../../../../hooks/useGetUserId";
import { useState } from "react";
import SecurityQuestionSelection from "../../../../components/SecurityQuestionSelection";
import Button from "../../../../components/Button/Button";
import { useUpdateUser } from "../../../../hooks/useUpdateUser";
import { SecurityQuestionsSelection } from "../../../../components/SecurityQuestionSelection/SecurityQuestionSelection";

const Security = () => {
  const userId = useGetUserId();
  const { mutate: updateUser, isLoading: isUpdatingUser } = useUpdateUser(
    userId,
    {
      onError: () =>
        sendErrorToast(
          "There was an error saving Security Questions, please try again"
        ),
      onSuccess: () => setIsEditing(false),
    }
  );
  const themePath = useGetThemePath();

  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);

  const { data: userSecurityQuestions, isLoading: isLoadingUserQuestions } =
    useGetUsersSecurityQuestions(undefined, userId, {
      onError: () =>
        sendErrorToast(
          "There was an error fetching the security questions, please try again"
        ),
      refetchOnWindowFocus: false,
    });

  const values =
    userSecurityQuestions?.map((question) => ({
      key: question.question,
      value: "*********",
    })) ?? [];

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSubmit = (
    securityQuestionsSelection: SecurityQuestionsSelection
  ) => {
    updateUser({
      security_answer1: securityQuestionsSelection.question1Answer,
      security_answer2: securityQuestionsSelection.question2Answer,
      security_question1: securityQuestionsSelection.question1Option,
      security_question2: securityQuestionsSelection.question2Option,
    });
  };

  const isLoading =
    isLoadingQuestions || isLoadingUserQuestions || isUpdatingUser;

  const currentValue = {
    question1Option: userSecurityQuestions?.[0]?.id ?? "",
    question2Option: userSecurityQuestions?.[1]?.id ?? "",
    question1Answer: "",
    question2Answer: "",
  };

  return (
    <Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={`0 0 ${getREMFromPX(theme.spacing * 3)} 0`}
      >
        <Stack direction="row" alignItems="center">
          <LockOutlinedIcon sx={getHeaderIconStyle(themePath)} />
          <Typography sx={getHeaderStyle}>
            {!isEditing ? "Security Questions" : "Edit Security Questions"}
          </Typography>
        </Stack>
        {!isEditing && (
          <Stack
            direction="row"
            alignItems="center"
            onClick={handleEdit}
            sx={getEditStyle(themePath)}
            component="button"
          >
            <Typography component="span"> Edit </Typography>
            <FontAwesomeIcon icon="pencil" />
          </Stack>
        )}
      </Stack>
      {isEditing ? (
        <SecurityQuestionSelection
          onSubmit={handleSubmit}
          setIsValid={setIsValid}
          setIsLoadingQuestions={setIsLoadingQuestions}
          previousValues={currentValue}
        >
          <>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              paddingTop={getREMFromPX(theme.spacing * 8)}
            >
              <Button
                data-testid="cancelButton"
                text="Cancel"
                type="button"
                size="large"
                variant="text"
                onClick={() => setIsEditing(false)}
              />
              <Button
                data-testid="saveButton"
                text="Save"
                type="submit"
                size="large"
                disabled={!isValid}
              />
            </Stack>
          </>
        </SecurityQuestionSelection>
      ) : (
        values.map((value, idx) => (
          <Stack
            direction="row"
            padding={`${getREMFromPX(theme.spacing * 6)} 0 0 0`}
            key={`security-property-${idx}`}
          >
            <Typography>{`${value.key}:`}</Typography>
            <Typography sx={getListValueStyle(themePath)}>
              {value.value}
            </Typography>
          </Stack>
        ))
      )}
      <Backdrop open={isLoading}>
        <CircularProgress data-testid="progressSpinner" color="inherit" />
      </Backdrop>
    </Stack>
  );
};

export default Security;
