import {
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useContext, useMemo } from "react";
import Button from "../../../../components/Button";
//import useGetThemePath from "../../../../hooks/useGetThemePath";
import { theme } from "../../../../theme";
import getREMFromPX from "../../../../utils/getREMFromPX";
import { Step1Type, Step2Type, Step3Type, WarrantyStepType } from "../../AccountSettings";
import { PaymentType, CreditCard, Ach } from "../../../../components/Payment"
import {
  headerStyle,
  dividerStyle,
  iconStyle,
} from "./Styles";
import {
  AddressAndAccountContext,
  AddressAndAccountContextType,
} from "../../../../components/AddressAndAccountProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useViewport  from "../../../../hooks/useViewport";

interface PaymentStepProps {
  changeStep: (args: PaymentType) => void;
  accountId?: string;
  previousValues: {
    step1: Step1Type;
    step2: Step2Type;
    step3: Step3Type;
    warrantyStep: WarrantyStepType;
    paymentStep: PaymentType;
  };
  goBackOneStep: () => void;
}

const PaymentStep = ({ changeStep, previousValues, goBackOneStep, accountId }: PaymentStepProps) => {
  const { isMobile } = useViewport();

  const { userInfo } = useContext(
    AddressAndAccountContext
  ) as AddressAndAccountContextType;

    // set warranty info, from previous step
    const warranty = useMemo(() => {
      const warrantyDetails = {
        processingFeeSel: previousValues.warrantyStep.processingFeeSel,
        paymentMethod: previousValues.warrantyStep.paymentMethod,
        maintenanceSel: previousValues.warrantyStep.maintenanceSel,
        insuranceSel: previousValues.warrantyStep.insuranceSel,
        withoutInsurance: previousValues.warrantyStep.withoutInsurance,
        maintainceCharges: previousValues.warrantyStep.maintainceCharges,
        insuranceCharges: previousValues.warrantyStep.insuranceCharges,
        ccProcessingCharges: previousValues.warrantyStep.ccProcessingCharges,
        subtotal: previousValues.warrantyStep.subtotal,
      }
      return warrantyDetails
    }, [previousValues.warrantyStep])
  
  // set location, either from previous step or from existing user data
  const location = useMemo(() => {
    const userLocation = userInfo?.data?.user?.Contact?.Location
    const billing = {
      billingStreet: previousValues.step2.address ?? userLocation?.address1 ?? "",
      billingCity: previousValues.step2.city ?? userLocation?.city ?? "",
      billingState: previousValues.step2.state ?? userLocation?.state ?? "",
      billingZip: previousValues.step2.zipCode ?? userLocation?.zip ?? "",
    }
    return billing
  }, [userInfo, previousValues.step2])

  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const disableButton = !isValid;

  const onSubmit = (payment: PaymentType) => {
    changeStep(payment);
  };

  const Payment = warranty?.paymentMethod === 'CC' ? CreditCard : Ach;

  return (
    <>
      <Stack spacing={getREMFromPX(theme.spacing * 2)}>
        <Typography sx={headerStyle} component="h1">
          Summary of Monthly Charges
        </Typography>
        <Typography>
        {`$${warranty.maintainceCharges.toFixed(2)}/month - Operations & Maintenance`}
        </Typography>
        {warranty.insuranceSel && (
          <Typography>
            {`$${warranty.insuranceCharges.toFixed(2)}/month - Device Insurance`}
          </Typography>
        )}
        {warranty.paymentMethod === 'CC' && (
          <Typography>
            {`$${warranty.ccProcessingCharges.toFixed(2)} - Credit Card Processing Fee`}
          </Typography>
        )}
        <Divider sx={dividerStyle} />
        <Typography>
          {`$${warranty.subtotal.toFixed(2)} - Amount to be charged automatically on the 1st of each month. First month is prorated.`} 
        </Typography>
        <Stack marginX={isMobile ? `${getREMFromPX(theme.spacing * 16)} !important` : `${getREMFromPX(theme.spacing * 28)} !important`}>
          <Typography>
            You will choose service plans in a later step after setup. Service plan fees will be paid 
            directly to the chosen service provider.
          </Typography>
        </Stack>
      </Stack>
      <Payment
        onSubmit={onSubmit}
        accountId={accountId}
        previousValues={previousValues.paymentStep}
        setIsValid={setIsValid}
        setIsLoading={setIsLoading}
        disableForm={false}
        location={location}
        warranty={warranty}
      >
        <>
          <Divider sx={dividerStyle} />
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={getREMFromPX(theme.spacing * 6)}
          >
            <Button
              startIcon={
                <FontAwesomeIcon icon="angle-left" style={iconStyle} />
              }
              onClick={goBackOneStep}
              variant="text"
              text="Back"
              size="medium"
            />
            <Button
              data-testid="continueButton"
              text="Continue"
              type="submit"
              size="medium"
              isLoading={isLoading}
              disabled={disableButton || isLoading}
            />
          </Stack>
        </>
      </Payment>
    </>
  );
};
export default PaymentStep;
