import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { endpoints } from "../api/constants/endpoints";
import { ValidationBody } from "../api/interfaces/User";
import fetcher from "../utils/fetchWrapper";

const validateUser = async (userName: string) => {
  const response = await fetcher(
    `${endpoints.validateUser}/?username=${userName}`
  );
  if (!response.ok) {
    throw new Error();
  }
  const body = await response.json();
  return body;
};

export const useValidateUser = (
  queryKey: string,
  options?: UseQueryOptions<
    ValidationBody,
    Error,
    ValidationBody,
    Array<string>
  >
): UseQueryResult<ValidationBody, Error> =>
  useQuery(["validateUser", queryKey], () => validateUser(queryKey), options);
